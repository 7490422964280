// import React from "react"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../styles/webinars/webinars.style.css"
import BHDueDiligenceModal from "../../components/bh-due-diligence-modal"
import useModalProps from "../../helpers/use-modal-props"
import "swiper/swiper.css"
import { graphql, useStaticQuery } from "gatsby"


function WebinarsPlayer() {

  /* const duediligencevideosacf = useStaticQuery(graphql`
    query {
      wpgraphql {
        page(id: "cG9zdDoyMTg3") {
          id
          duediligencevideosacf {
            disclosuresModalData {
              content {
                fieldGroupName
                text
                title
              }
              fieldGroupName
              title
            }
            modalAcceptButtonLabel
            fieldGroupName
            modalCloseButtonLabel
            modalFooterText
            riskFactorsModalData {
              content {
                fieldGroupName
                text
                title
              }
              fieldGroupName
              title
            }
          }
        }
      }
    }
  `) */

const { modalProps } = useModalProps({ initialOpen: initialOpen })
const { modalStatus } = modalProps

var initialOpen = true
// const [initialOpen, setInitialOpen] = React.useState(null);

if (typeof window !== "undefined") {
  if (
    window.localStorage.length == 0 ||
    window.localStorage.getItem("bh-disclosureNoticesStatus") !== "accepted"
  ) {
    // console.log('do we have this value?', window.localStorage, (window.localStorage.getItem('bh-disclosureNoticesStatus') !== 'accepted'), window.localStorage.length == 0);
    // setInitialOpen(false);
    initialOpen = false
  }
}
  
  return (
    <Layout 
      disableAgreementModal={true}
      onAccept={() => {
        modalProps?.openModal({})
      }}
      modalStateOverride={!modalStatus?.open}
    >
      <SEO title="Webinars" />

      <div className="p-container-wrap txt-center" style={{color: "#BDB4A8"}}>
        <div className="breadcrumb"><a href="/webinars">Test</a> Vimeo test</div>
        <h2 className="txt-dark txt-h2">
          Test Live Webinar 
        </h2>
        <h4 className="txt-dark txt-player-title">
          with Vimeo 
        </h4>
      </div>
      <div className="player-container">
        <div className="player-wrapper">
          <div className="player-iframe-container">
            <iframe 
              src="https://vimeo.com/event/4162385/embed/interaction" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen 
              className="vimeo-iframe responsive-iframe no-border"
              width="740"
              height="460"
            />
            {/* <iframe 
              src="https://vimeo.com/live/interaction_tools/live_event/4168039" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen 
              className="vimeo-iframe responsive-iframe no-border"
              width="740"
              height="460"
            /> */}
          </div>
        </div>
      </div>
      <p className="txt-dark txt-vid-discr txt-center">
        Meet the Brevan Howard team and learn about their multi-PM discretionary global macro fund which is now available for investment at LPL.
      </p>
      
      {/* <BHDueDiligenceModal modalProps={modalProps} digitalassetsacf={duediligencevideosacf.wpgraphql.page.duediligencevideosacf} /> */}
      
    </Layout>
  )
}

export default WebinarsPlayer
